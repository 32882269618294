$(document).ready(function()
{
 addAction();
 addInput();
 calendar();
 changeBtnAction();
 changeTypeFile();
 deleteAction();
 deleteRole();
 disabledAction();
 disabledCompany();
 disabledGame();
 disabledType();
 disabledUser();
 getActions();
 loadFilter();
 loadSession();
 removeAction();
 removeInput();
 search();
 selectType();
 showUser();
 validate();
 validateAction();
});

function addAction()
{
 $('#btnDelete').attr('disabled', 'disabled');

 $('#btnSum').click(function()
 {
  let quantity = $('.clonarAction').length;
  let id = new Number(quantity + 1);
  let box = $('#box');
  let boxAction = $('#boxAction' + quantity).clone().attr('id', 'boxAction' + id);

  boxAction.children(':first').attr('id', 'inputAction' + id).attr('name', 'inputAction' + id).attr('required', 'true');

  $(box).append(boxAction);

  $("#btnDelete").removeAttr('disabled');
  if(id==30) $('#btnSum').attr('disabled', 'disabled');
 });
}

function addInput()
{
 $('#btnRemove').attr('disabled', 'disabled');

 $('#btnAdd').click(function()
 {
  let quantity = $('.clonarName').length;
  let id = new Number(quantity + 1);
  let box = $('#box');
  let boxName = $('#boxName' + quantity).clone().attr('id', 'boxName' + id);
  let boxType = $('#boxType' + quantity).clone().attr('id', 'boxType' + id);
  let boxDescription = $('#boxDescription' + quantity).clone().attr('id', 'boxDescription' + id);

  boxName.children(':first').attr('id', 'inputName' + id).attr('name', 'inputName' + id).attr('required', 'true');
  boxType.children(':first').attr('id', 'inputType' + id).attr('name', 'inputType' + id).attr('required', 'true');
  boxDescription.children(':first').attr('id', 'inputDescription' + id).attr('name', 'inputDescription' + id).attr('required', 'true');

  $(box).append(boxName);
  $(box).append(boxType);
  $(box).append(boxDescription);

  $("#btnRemove").removeAttr('disabled');
  if(id==30) $('#btnAdd').attr('disabled', 'disabled');
 });
}

function calendar()
{
 $(".readonly").keydown(function(e){
  e.preventDefault();
 });

 $('.calendar').datetimepicker({
  allowInputToggle: true,
  useCurrent: false,
  ignoreReadonly: true,
  format: 'Y-MM-DD hh:mm:ss A',
  widgetPositioning:{
   horizontal: 'auto',
   vertical: 'auto'
  }
 });
}

function changeBtnAction()
{
 let quantity = 0;

 $('.action').on('click', function()
 {
  quantity = $('.action:checked').length;

  if(quantity>0)
   $('.btnAction').removeClass('d-none');
  else
   $('.btnAction').addClass('d-none');
 });
}

function changeTypeFile()
{
 jQuery('.input-user').change(function()
 {
  let filename = jQuery(this).val().split('\\').pop();
  let label = $("label[for='" + $(this).attr('id') + "']");
  $(label).text(filename);
  $(label).addClass(['bg-white', 'text-primary']);
  $('.btn-primary').removeClass('d-none');
 });
}

function deleteAction()
{
 $(document).on('click', '.deleteAction', function()
 {
  let id = $(this).attr('id');

  swal({
   title: "¿Realmente deseas quitar esta acción del juego?",
   text: "Al hacerlo ya no estará relacionada a este juego",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/game/delete",
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'POST',
     data: { id },
     success: function(data)
     {
      swal({
       title: "La acción seleccionada ha sido retirada de este juego.",
       text: "¡Buen Trabajo!",
       type: "success"
      })
     }
    });
   }
   else
   {
    swal("Sin Cambios", "La acción seleccionada no ha sido afectada.", "info");
    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function deleteRole()
{
 $(document).on('click', '.deleteRole', function()
 {
  let id = $(this).attr('id');

  swal({
   title: "¿Realmente deseas eliminar el rol seleccionado?",
   text: "¡Esta acción es irreversible!",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-info",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/role/delete",
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'POST',
     data: { id },
     success: function(data)
     {
      swal({
       title: "El rol seleccionado ha sido eliminado.",
       text: "¡Buen Trabajo!",
       type: "success"
      })
     }
    });
   }
   else
   {
    swal("Sin Cambios", "El rol seleccionado no ha sido afectado.", "info");
    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function disabledAction()
{
 $(document).on('click', '.disabledAction', function()
 {
  let id = $(this).attr('id');

  swal({
   title: "¿Realmente deseas cambiar el estado de esta acción?",
   text: "Al hacerlo podría aparecer o no al asignarla a un juego",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/action/change",
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'POST',
     data: { id },
     success: function(data)
     {
      if(data=='Activo')
      {
       swal({
        title: "La acción seleccionada ha sido activada.",
        text: "¡Buen Trabajo!",
        type: "success"
       })
      }
      else
      {
       swal({
        title: "La acción seleccionada ha sido desactivada.",
        text: "¡Atención!",
        type: "warning"
       })
      }
     }
    });
   }
   else
   {
    swal("Sin Cambios", "La acción seleccionada no ha sido afectada.", "info");
    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function disabledCompany()
{
 $(document).on('click', '.disabledCompany', function()
 {
  let id = $(this).attr('id');

  swal({
   title: "¿Realmente deseas cambiar el estado de este empresa?",
   text: "Al hacerlo podría aparecer o no al asignarla a un juego",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/company/change",
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'POST',
     data: { id },
     success: function(data)
     {
      if(data=='Activo')
      {
       swal({
        title: "La empresa seleccionada ha sido activada.",
        text: "¡Buen Trabajo!",
        type: "success"
       })
      }
      else
      {
       swal({
        title: "La empresa seleccionada ha sido desactivada.",
        text: "¡Atención!",
        type: "warning"
       })
      }
     }
    });
   }
   else
   {
    swal("Sin Cambios", "La empresa seleccionada no ha sido afectada.", "info");
    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function disabledGame()
{
 $(document).on('click', '.disabledGame', function()
 {
  let id = $(this).attr('id');

  swal({
   title: "¿Realmente deseas cambiar el estado de este juego?",
   text: "Al hacerlo podría aparecer o no al asignarlo a una empresa",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/game/change",
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'POST',
     data: { id },
     success: function(data)
     {
      if(data=='Activo')
      {
       swal({
        title: "El juego seleccionado ha sido activado.",
        text: "¡Buen Trabajo!",
        type: "success"
       })
      }
      else
      {
       swal({
        title: "El juego seleccionado ha sido desactivado.",
        text: "¡Atención!",
        type: "warning"
       })
      }
     }
    });
   }
   else
   {
    swal("Sin Cambios", "El juego seleccionado no ha sido afectado.", "info");
    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function disabledType()
{
 $(document).on('click', '.disabledType', function()
 {
  let id = $(this).attr('id');

  swal({
   title: "¿Realmente deseas cambiar el estado de este tipo de acción?",
   text: "Al hacerlo podría aparecer o no al asignarlo a una acción",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/type/change",
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'POST',
     data: { id },
     success: function(data)
     {
      if(data=='Activo')
      {
       swal({
        title: "El tipo de acción seleccionado ha sido activado.",
        text: "¡Buen Trabajo!",
        type: "success"
       })
      }
      else
      {
       swal({
        title: "El tipo de acción seleccionado ha sido desactivado.",
        text: "¡Atención!",
        type: "warning"
       })
      }
     }
    });
   }
   else
   {
    swal("Sin Cambios", "El tipo de acción seleccionado no ha sido afectado.", "info");
    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function disabledUser()
{
 $(document).on('click', '.disabledUser', function()
 {
  let id = $(this).attr('id');

  swal({
   title: "¿Realmente deseas cambiar el estado de este usuario?",
   text: "Al hacerlo podría negarle o aprobarle el acceso al sistema",
   type: "warning",
   showCancelButton: true,
   confirmButtonClass: "btn-danger",
   confirmButtonText: "Sí, procede!",
   cancelButtonText: "No, cancela la operación!",
   closeOnConfirm: false,
   closeOnCancel: false
  },

  function(isConfirm)
  {
   if(isConfirm)
   {
    $.ajax({
     url: "/user/change",
     headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
     type: 'POST',
     data: { id },
     success: function(data)
     {
      if(data=='Activo')
      {
       swal({
        title: "El usuario seleccionado ha sido activado.",
        text: "¡Buen Trabajo!",
        type: "success"
       })
      }
      else
      {
       swal({
        title: "El usuario seleccionado ha sido desactivado.",
        text: "¡Atención!",
        type: "warning"
       })
      }
     }
    });
   }
   else
   {
    swal("Sin Cambios", "El usuario seleccionado no ha sido afectado.", "info");
    return false;
   }
   setTimeout(function(){ window.location.reload(); }, 1500);
  });
 });
}

function getActions()
{
 $('.btnAction').on('click', function()
 {
  let arrayActions = $.map($('.action:checkbox:checked'), function(val, i) { return val.value; });
  let game_id = $('#game_id').val();

  storeMetric(arrayActions, game_id);
 });
}

function loadFilter() //En construccion
{
 $(".select_companies").change(function()
 {
  let company_id = $('.select_companies').val();

  $("#box-game-2").removeClass("d-none");
  $(".select_game_2").load(`/session/game/${company_id}`);
 });

 $(".select_companies").keyup(function()
 {
  let company_id = $('.select_companies').val();

  $("#box-game-2").removeClass("d-none");
  $(".select_game_2").load(`/session/game/${company_id}`);
 });

 $(".select_game_2").keyup(function()
 {
  $("#box-date-max").removeClass("d-none");
  $("#box-date-min").removeClass("d-none");
  $("#box-type-2").removeClass("d-none");
 });

 $(".select_game_2").change(function()
 {

  $("#box-date-max").removeClass("d-none");
  $("#box-date-min").removeClass("d-none");
  $("#box-type-2").removeClass("d-none");
 });

 $(".select_type_2").change(function(){
  $("#sendFilter").removeClass("d-none");
 });

 $(".select_type_2").keyup(function(){
  $("#sendFilter").removeClass("d-none");
 });

 $('#sendFilter').click(function()
 {
  let company_id = $('.select_companies').val();
  let date_max = $('#date_max').val();
  let date_min = $('#date_min').val();
  let game_id = $('.select_game_2').val();
  let type = $('.select_type_2').val();

  if(type=='table')
  {
   $.ajax({
    url: '/session/answer',
    headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
    type: 'POST',
    beforeSend: function(xhr){xhr.setRequestHeader('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'));},
    data: { company_id, game_id, date_max, date_min, type },
    success: function(data)
    {
     $("#box-filters").removeClass("d-none");
     $('#box-filters').html(data);
    },
    error: function(jqXHR, textStatus, errorThrown)
    {
     if(jqXHR.status === 0)
     {
      swal({
       title: 'Atención',
       text: 'No se han arrojado resultados porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.',
       type: 'error',
       confirmButtonClass: 'btn-warning'
      });
     }
     else if(jqXHR.status == 404)
     {
      swal({
       title: 'Error',
       text: 'No se han arrojado resultados porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.',
       type: 'error',
       confirmButtonClass: 'btn-warning'
      });
     }
     else if(jqXHR.status == 500)
     {
      swal({
       title: 'Error',
       text: 'No se han arrojado resultados porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.',
       type: 'error',
       confirmButtonClass: 'btn-warning'
      });
     }
     else if(textStatus === 'parsererror')
     {
      swal({
       title: 'Error',
       text: 'No se han enviado los datos de forma correcta. Por favor vuelve a intentarlo.',
       type: 'error',
       confirmButtonClass: 'btn-warning'
      });
     }
     else if(textStatus === 'timeout')
     {
      swal({
       title: 'Error',
       text: 'El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. Por favor vuelve a intentarlo.',
       type: 'error',
       confirmButtonClass: 'btn-warning'
      });
     }
     else if(textStatus === 'abort')
     {
      swal({
       title: 'Error',
       text: 'La petición enviada ha sido rechazada por el servidor. Por favor vuelve a intentarlo.',
       type: 'error',
       confirmButtonClass: 'btn-warning'
      });
     }
     else
     {
      swal({
       title: 'Atención',
       text: 'No se han arrojado resultados porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.',
       type: 'error',
       confirmButtonClass: 'btn-warning'
      });
     }
    }
   });
  }
  else if(type=='excel')
  {
   company_id, game_id, date_max, date_min

   $.get(`/session/downloadFilter/${company_id}/${game_id}/${date_max}/${date_min}`, function(data){
    window.location = `/session/downloadFilter/${company_id}/${game_id}/${date_max}/${date_min}`;
   });
  }
 });
}

function loadSession()
{
 let arraySessions = [];

 $(".select_empresa").change(function()
 {
  let company = $('.select_empresa').val();

  $("#box-game").removeClass("d-none");
  $(".select_juego").load(`/session/game/${company}`);
 });

 $(".select_empresa").keyup(function()
 {
  let company = $('.select_empresa').val();

  $("#box-game").removeClass("d-none");
  $(".select_juego").load(`/session/game/${company}`);
 });

 $(".select_juego").change(function()
 {
  let game = $('.select_juego').val();

  $("#box-jugador").removeClass("d-none");
  $(".select_jugador").load(`/session/user/${game}`);
 });

 $(".select_juego").keyup(function()
 {
  let game = $('.select_juego').val();

  $("#box-jugador").removeClass("d-none");
  $(".select_jugador").load(`/session/user/${game}`);
 });

 $(".select_jugador").change(function()
 {
  let game = $('.select_juego').val();
  let player = $('.select_jugador').val();

  $("#box-session").removeClass("d-none");
  $(".select_session").load(`/session/sesion/${player}/${game}`);
 });

 $(".select_jugador").keyup(function()
 {
  let game = $('.select_juego').val();
  let player = $('.select_jugador').val();

  $("#box-session").removeClass("d-none");
  $(".select_session").load(`/session/sesion/${player}/${game}`);
 });

 $(".select_session").change(function()
 {
  let session = $('.select_session').val();
  arraySessions.push(session);

  $("#box-type").removeClass("d-none");
 });

 $(".select_session").keyup(function()
 {
  let session = $('.select_session').val();
  arraySessions.push(session);

  $("#box-type").removeClass("d-none");
 });

 $(".select_type").change(function(){
  $("#sendSession").removeClass("d-none");
 });

 $(".select_type").keyup(function(){
  $("#sendSession").removeClass("d-none");
 });

 $('#sendSession').click(function()
 {
  let type = $('.select_type').val();
  let game = $('.select_juego').val();

  if(type=='table')
  {
   $("#box-results").removeClass("d-none");
   $("#box-results").load(`/session/result/${arraySessions}/${game}`);
  }
  else if(type=='excel')
  {
   $.get(`/session/downloadComparison/${arraySessions}/${game}`, function(data){
    window.location = `/session/downloadComparison/${arraySessions}/${game}`;
   });
  }
 });
}

function removeAction()
{
 $('#btnDelete').attr('disabled', 'disabled');

 $('#btnDelete').click(function()
 {
  let quantity = $('.clonarAction').length;

  $('#boxAction' + quantity).remove();
  $("#btnSum").removeAttr('disabled');

  if(quantity-1==1) $('#btnDelete').attr('disabled', 'disabled');
 });
}

function removeInput()
{
 $('#btnRemove').attr('disabled', 'disabled');

 $('#btnRemove').click(function()
 {
  let quantity = $('.clonarName').length;

  $('#boxName' + quantity).remove();
  $('#boxType' + quantity).remove();
  $('#boxDescription' + quantity).remove();
  $("#btnAdd").removeAttr('disabled');

  if(quantity-1==1) $('#btnRemove').attr('disabled', 'disabled');
 });
}

function search()
{
 $('.select_company').select2();
 $('.select_empresa').select2();
 $('.select_juego').select2();
 $('.select_jugador').select2();
 $('.select_session').select2();
 $('.select_type').select2();
 $('.select_type_user').select2();
}

function selectType()
{
 $(".select_type_user").change(function()
 {
  let type_user = $('.select_type_user').val();

  if(type_user==='Administrator')
  {
   $(".div-company").removeClass("d-none");
   $(".div-roles").removeClass("d-none");
   $(".div-password").removeClass("d-none");
   $(".div-password_confirmation").removeClass("d-none");
   $("#password").attr("required", "true");
   $("#password_confirmation").attr("required", "true");
   $("#company_id").removeClass("select_company");
   $("#company_id").load('/company/show/1');
  }
  else if(type_user==='Client')
  {
   $(".div-company").removeClass("d-none");
   $(".div-roles").removeClass("d-none");
   $(".div-password").removeClass("d-none");
   $(".div-password_confirmation").removeClass("d-none");
   $("#password").attr("required", "true");
   $("#password_confirmation").attr("required", "true");
   $("#company_id").addClass("select_company");
   $("#company_id").load('/company/showAll');
  }
  else if(type_user==='Player')
  {
   $(".div-company").removeClass("d-none");
   $(".div-roles").addClass("d-none");
   $(".div-password").addClass("d-none");
   $(".div-password_confirmation").addClass("d-none");
   $("#password").attr("required", "false");
   $("#password_confirmation").attr("required", "false");
   $("#company_id").addClass("select_company");
   $("#company_id").load('/company/showAll');
  }
 });

 $(".select_type_user").keyup(function()
 {
  let type_user = $('.select_type_user').val();

  if(type_user==='Administrator')
  {
   $(".div-company").removeClass("d-none");
   $(".div-roles").removeClass("d-none");
   $(".div-password").removeClass("d-none");
   $(".div-password_confirmation").removeClass("d-none");
   $("#password").attr("required", "true");
   $("#password_confirmation").attr("required", "true");
   $("#company_id").removeClass("select_company");
   $("#company_id").load('/company/show/1');
  }
  else if(type_user==='Client')
  {
   $(".div-company").removeClass("d-none");
   $(".div-roles").removeClass("d-none");
   $(".div-password").removeClass("d-none");
   $(".div-password_confirmation").removeClass("d-none");
   $("#password").attr("required", "true");
   $("#password_confirmation").attr("required", "true");
   $("#company_id").addClass("select_company");
   $("#company_id").load('/company/showAll');
  }
  else if(type_user==='Player')
  {
   $(".div-company").removeClass("d-none");
   $(".div-roles").addClass("d-none");
   $(".div-password").addClass("d-none");
   $(".div-password_confirmation").addClass("d-none");
   $("#password").attr("required", "false");
   $("#password_confirmation").attr("required", "false");
   $("#company_id").addClass("select_company");
   $("#company_id").load('/company/showAll');
  }
 });
}

function showUser()
{
 $(document).on('click', '.showUser', function()
 {
  let id = $(this).attr('id');

  $.ajax({
   url: '/user/show/'+id,
   success: function(user)
   {
    swal({
     title: user.name,
     html: true,
     text:
      '<div class=text-left>'
      +' '+
      '<p>Cedula: '+user.cedula+'</p>'
      +' '+
      '<p>N° Teléfono: '+user.phone+'</p>'
      +' '+
      '<p>Dirección: '+user.address+'</p>'
      +' '+
      '<p>Género: '+user.gender+'</p>'
      +' '+
      '<p>Cargo: '+user.charge+'</p>'
      +' '+
      '</div>',
     type: 'info',
     confirmButtonClass: 'btn-success',
     confirmButtonText: 'Cerrar',
     closeOnConfirm: false
    })
   }
  });
 });
}

function validate()
{
 $('#btnValidate').click(function()
 {
  let arrayDescriptions = [];
  let arrayNames = [];
  let arrayTypes = [];
  let quantity = $('.clonarName').length;

  for(var i=1;i<=quantity;i++)
  {
   if($('#inputName'+i).val()==null || $('#inputName'+i).val()=='')
   {
    swal({
     title: "¡Advertencia!",
     text: "Debe ingresar el nombre para cada acción agregada",
     type: "warning"
    });
    $('#inputName'+i).focus();

    return false;
   }

   if($('#inputType'+i).val()==null || $('#inputType'+i).val()=='')
   {
    swal({
     title: "¡Advertencia!",
     text: "Debe elegir un tipo de acción para cada acción agregada",
     type: "warning"
    });
    $('#inputType'+i).focus();

    return false;
   }
  }

  for(var i=1;i<=quantity;i++)
  {
   arrayNames.push($('#inputName'+i).val());
   arrayTypes.push($('#inputType'+i).val());
   arrayDescriptions.push($('#inputDescription'+i).val());
  }

  store(arrayNames, arrayTypes, arrayDescriptions, quantity);
 });
}

function validateAction()
{
 $('#btnValidateAction').click(function()
 {
  let player = $('.select_user').val();
  let game = $('.select_game').val();
  let arrayActions = [];
  let quantity = $('.clonarAction').length;

  if(player==null || player=='')
  {
   swal({
    title: "¡Advertencia!",
    text: "Debe elegir un jugador",
    type: "warning"
   });
   $('#select_user'+i).focus();

   return false;
  }

  if(game==null || game=='')
  {
   swal({
    title: "¡Advertencia!",
    text: "Debe elegir un juego",
    type: "warning"
   });
   $('#select_game'+i).focus();

   return false;
  }

  for(var i=1;i<=quantity;i++)
  {
   if($('#inputAction'+i).val()==null || $('#inputAction'+i).val()=='')
   {
    swal({
     title: "¡Advertencia!",
     text: "Debe ingresar un valor para cada acción agregada",
     type: "warning"
    });
    $('#inputAction'+i).focus();

    return false;
   }
  }

  for(var i=1;i<=quantity;i++)
  {
   arrayActions.push($('#inputAction'+i).val());
  }

  storeAction(player, game, arrayActions, quantity);
 });
}

function store(arrayNames, arrayTypes, arrayDescriptions, quantity)
{
 $.ajax({
  url: '/action',
  headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
  type: 'POST',
  data: { arrayNames, arrayTypes, arrayDescriptions},
  success: function(data)
  {
   setTimeout(function()
   {
    swal({
     title: "¡Correcto!",
     text: "Se han creado las acciones de manera exitosa.",
     type: "success",
     confirmButtonText: "OK",
     confirmButtonClass: 'btn-success'
    }, 0);
   });

   for(var i=1;i<=quantity;i++)
    $('#inputName'+i).val('');

   for(var i=1;i<=quantity;i++)
    $('#inputType'+i).val('');

   for(var i=1;i<=quantity;i++)
    $('#inputDescription'+i).val('');

   $('#btnValidate').attr("disabled", false);
   setTimeout(function(){ window.location.reload(); }, 1500);
  },
  error: function(jqXHR, textStatus, errorThrown)
  {
   if(jqXHR.status === 0)
   {
    swal({
     title: 'Atención',
     text: 'No se han guardado las acciones porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }
   else if(jqXHR.status == 404)
   {
    swal({
     title: 'Error',
     text: 'No se han guardado las acciones porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }
   else if(jqXHR.status == 500)
   {
    swal({
     title: 'Error',
     text: 'No se han guardado las acciones porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }
   else if(textStatus === 'parsererror')
   {
    swal({
     title: 'Error',
     text: 'No se han enviado los datos de forma correcta. No se han guardado las acciones, por favor vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }
   else if(textStatus === 'timeout')
   {
    swal({
     title: 'Error',
     text: 'El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han guardado las acciones, por favor vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }
   else if(textStatus === 'abort')
   {
    swal({
     title: 'Error',
     text: 'La petición enviada ha sido rechazada por el servidor. No se han guardado las acciones, por favor vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }
   else
   {
    swal({
     title: 'Atención',
     text: 'No se han guardado las acciones porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }

   for(var i=1;i<=quantity;i++)
    $('#inputName'+i).val('');

   for(var i=1;i<=quantity;i++)
    $('#inputType'+i).val('');

   for(var i=1;i<=quantity;i++)
    $('#inputDescription'+i).val('');

   $('#btnValidate').attr("disabled", false);
   setTimeout(function(){ window.location.reload(); }, 1500);
  }
 });
}

function storeAction(player, game, arrayActions, quantity)
{
 $.ajax({
  url: '/session',
  headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
  type: 'POST',
  data: { player, game, arrayActions},
  success: function(data)
  {
   setTimeout(function()
   {
    swal({
     title: "¡Correcto!",
     text: "Se ha creado la sesión de manera exitosa.",
     type: "success",
     confirmButtonText: "OK",
     confirmButtonClass: 'btn-success'
    }, 0);
   });

   for(var i=1;i<=quantity;i++)
    $('#inputAction'+i).val('');

   $('#btnValidateAction').attr("disabled", false);
   setTimeout(function(){ window.location.reload(); }, 1500);
  },
  error: function(jqXHR, textStatus, errorThrown)
  {
   if(jqXHR.status === 0)
   {
    swal({
     title: 'Atención',
     text: 'No se han guardado los datos porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }
   else if(jqXHR.status == 404)
   {
    swal({
     title: 'Error',
     text: 'No se han guardado los datos porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }
   else if(jqXHR.status == 500)
   {
    swal({
     title: 'Error',
     text: 'No se han guardado los datos porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }
   else if(textStatus === 'parsererror')
   {
    swal({
     title: 'Error',
     text: 'No se han enviado los datos de forma correcta. No se han guardado los datos, por favor vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }
   else if(textStatus === 'timeout')
   {
    swal({
     title: 'Error',
     text: 'El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han guardado los datos, por favor vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }
   else if(textStatus === 'abort')
   {
    swal({
     title: 'Error',
     text: 'La petición enviada ha sido rechazada por el servidor. No se han guardado los datos, por favor vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }
   else
   {
    swal({
     title: 'Atención',
     text: 'No se han guardado los datos porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }

   for(var i=1;i<=quantity;i++)
    $('#inputAction'+i).val('');

   $('#btnValidateAction').attr("disabled", false);
   setTimeout(function(){ window.location.reload(); }, 1500);
  }
 });
}

function storeMetric(arrayActions, game_id)
{
 $.ajax({
  url: '/game/metrics',
  headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
  type: 'POST',
  data: { arrayActions, game_id},
  success: function(data)
  {
   setTimeout(function()
   {
    swal({
     title: "¡Correcto!",
     text: "Se han asignado las acciones de manera exitosa.",
     type: "success",
     confirmButtonText: "OK",
     confirmButtonClass: 'btn-success'
    }, 0);
   });

   setTimeout(function(){ window.location.reload(); }, 1500);
  },
  error: function(jqXHR, textStatus, errorThrown)
  {
   if(jqXHR.status === 0)
   {
    swal({
     title: 'Atención',
     text: 'No se han asignado las acciones porque se ha perdido la conexión a Internet, por favor revisa tu conexión y vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }
   else if(jqXHR.status == 404)
   {
    swal({
     title: 'Error',
     text: 'No se han asignado las acciones porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }
   else if(jqXHR.status == 500)
   {
    swal({
     title: 'Error',
     text: 'No se han asignado las acciones porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }
   else if(textStatus === 'parsererror')
   {
    swal({
     title: 'Error',
     text: 'No se han enviado los datos de forma correcta. No se han asignado las acciones, por favor vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }
   else if(textStatus === 'timeout')
   {
    swal({
     title: 'Error',
     text: 'El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han asignado las acciones, por favor vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }
   else if(textStatus === 'abort')
   {
    swal({
     title: 'Error',
     text: 'La petición enviada ha sido rechazada por el servidor. No se han asignado las acciones, por favor vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }
   else
   {
    swal({
     title: 'Atención',
     text: 'No se han asignado las acciones porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.',
     type: 'error',
     confirmButtonClass: 'btn-warning'
    });
   }

   setTimeout(function(){ window.location.reload(); }, 1500);
  }
 });
}
